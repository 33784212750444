import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import React from 'react';
import clsx from 'clsx';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import { isAuthenticatedState, localeState } from '../../api/appState';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import { customNavigationLinks, showContactPage, showDownloadDocuments, showTermsAndConditions } from '../../config';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export type NavigationLinksProps = StandardDivProps<NavigationLinksClassKey> & { isAsideLayout?: boolean };

export type NavigationLinksClassKey = 'root' | 'navLink' | 'linkActive' | 'asideLayoutnavLink';

const themeSettings = { name: 'NavigationLinks' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<NavigationLinksClassKey, {}>({
      root: {},
      navLink: {
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.appBar.color.contrastText,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      asideLayoutnavLink: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ed1c24',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      linkActive: {
        textDecoration: 'underline',
      },
    }),
  themeSettings,
);

const NavigationLinks = React.forwardRef<HTMLDivElement, NavigationLinksProps>(
  ({ className, classes: pClasses, isAsideLayout = false }, ref) => {
    const routeUrlFactory = useRouteUrlFactory();
    const t = useTranslateWithFallback();
    const classes = useStyles({ classes: pClasses });
    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const locale = useRecoilValue(localeState);
    const location = useLocation();

    const isActiveLink = (path: string) => location.pathname === path;

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {!isAuthenticated && (
          <Link
            component={RouterLink}
            to={routeUrlFactory('homePage')}
            color='textPrimary'
            variant='inherit'
            className={
              isAsideLayout
                ? classes.asideLayoutnavLink
                : clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('homePage')) })
            }
            noWrap
          >
            {t('mainNavigation/home')}
          </Link>
        )}

        {isAuthenticated && (
          <>
            <Link
              component={RouterLink}
              to={routeUrlFactory('dashboard')}
              color='textPrimary'
              variant='inherit'
              className={
                isAsideLayout
                  ? classes.asideLayoutnavLink
                  : clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('dashboard')) })
              }
              noWrap
            >
              {t('mainNavigation/home')}
            </Link>
            <Link
              component={RouterLink}
              to={routeUrlFactory('catalogue')}
              color='textPrimary'
              variant='inherit'
              className={
                isAsideLayout
                  ? classes.asideLayoutnavLink
                  : clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('catalogue')) })
              }
              noWrap
            >
              {t('mainNavigation/products')}
            </Link>
          </>
        )}

        {customNavigationLinks &&
          customNavigationLinks.map((link, idx) => (
            <Link
              href={link.href}
              key={idx}
              color='textPrimary'
              className={isAsideLayout ? classes.asideLayoutnavLink : classes.navLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {link.label[locale]}
            </Link>
          ))}
        {showTermsAndConditions && (
          <Link
            component={RouterLink}
            to={routeUrlFactory('termsAndConditions')}
            color='textPrimary'
            variant='inherit'
            className={
              isAsideLayout
                ? classes.asideLayoutnavLink
                : clsx(classes.navLink, {
                    [classes.linkActive]: isActiveLink(routeUrlFactory('termsAndConditions')),
                  })
            }
            noWrap
          >
            {t('mainNavigation/tos')}
          </Link>
        )}
        {showContactPage && (
          <Link
            component={RouterLink}
            to={routeUrlFactory('contacts')}
            color='textPrimary'
            variant='inherit'
            className={
              isAsideLayout
                ? classes.asideLayoutnavLink
                : clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('contacts')) })
            }
            noWrap
          >
            {t('mainNavigation/contacts')}
          </Link>
        )}
        {showDownloadDocuments && isAuthenticated && (
          <Link
            component={RouterLink}
            to={routeUrlFactory('downloadDocuments')}
            color='textPrimary'
            variant='inherit'
            className={
              isAsideLayout
                ? classes.asideLayoutnavLink
                : clsx(classes.navLink, {
                    [classes.linkActive]: isActiveLink(routeUrlFactory('downloadDocuments')),
                  })
            }
            noWrap
          >
            {t('mainNavigation/download')}
          </Link>
        )}
      </div>
    );
  },
);

NavigationLinks.displayName = 'NavigationLinks';

export default withThemeProps(themeSettings)(NavigationLinks);
